import { Injectable } from '@angular/core';

import { DatetimeHelper } from '../helpers/datetime.helper';
import { TransactionModel } from '../../../models/transactions/transaction.model';
import {
    AssignedTransactionTableModel,
    TransactionTableModel
} from '../../../models/transactions/transactions-table.model';
import { TransactionStatusTypeUiText } from '../../enums/transactions.enum';
import { AssignedTransactionModel } from '../../../models/transactions/assigned-transaction.model';
import { TransactionHelper } from '../helpers/transaction.helper';
import { ReconciliationType } from '../../enums/reconciliation-type.enum';

@Injectable()
export class TransactionsMappingService {

    constructor() {
    }

    public TransactionsMappingForTable(data: TransactionModel[], type?: ReconciliationType): TransactionTableModel[] {
        return data.map(element => {
            return {
                id: element.id,
                bagLabelCode: element.bagLabelCode,
                source: element.source,
                clientName: element.clientName,
                showClientWarning: TransactionHelper.showClientWarning(element),
                locationName: TransactionHelper.getLocationNameOrId(element),
                showLocationWarning: TransactionHelper.showLocationWarning(element),
                userName: TransactionHelper.getUserNameOrId(element),
                showUserWarning: TransactionHelper.showUserWarning(element),
                cpId: element.cpId,
                showCpidWarning: TransactionHelper.showCpidWarning(element),
                postedAmount: TransactionHelper.getPostedAmount(element),
                mpAmount: TransactionHelper.getMPAmount(element),
                depositDate: DatetimeHelper.getLocalDateWithTime(element.depositDate),
                status: element.status,
                viewActions: TransactionHelper.viewActions(element, type),
                CCY: 'RON',
                isNegative: TransactionHelper.isNegative(element),
                isPositive: TransactionHelper.isPositive(element),
                isClickable: TransactionHelper.isClickable(element),
                popoverContent: TransactionHelper.getPopoverContent(this.assignedTransactionsMapping(element.assignedTransactions))
            };
        });
    }

    public assignedTransactionsMapping(transactions: AssignedTransactionModel[]): AssignedTransactionTableModel[] {
        return transactions?.length ?
            transactions.map(item => {
                return {
                    bagLabelCode: item.bagLabelCode,
                    postedAmount: item.postedAmount.toString(),
                    negativeAmountUsed: item.negativeAmountUsed.toString(),
                    depositDate: DatetimeHelper.getLocalDateWithTime(item.depositDate),
                    source: item.source,
                    status: TransactionStatusTypeUiText[item.status]
                };
            })
            : null;
    }
}
