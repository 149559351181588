import * as moment from 'moment';

export class DatetimeHelper {
    public static defaultDateTimeFormat = 'DD/MM/YY HH:mm';
    public static shortDateFormat = 'DD/MM/YY';
    public static shortDateWithFullYearFormat = 'DD/MM/YYYY';

    static getLocalDateWithTime(utcDate: string): string {
        return utcDate ? moment.parseZone(utcDate).local().format(this.defaultDateTimeFormat) : '—';
    }

    static getDateWithTime(utcDate: string): string {
        return moment(new Date(utcDate)).format(this.defaultDateTimeFormat);
    }

    static getShortLocalDate(utcDate: string): string {
        return moment.parseZone(utcDate).local().format(this.shortDateFormat);
    }

    static getShortLocalDateWithFullYear(utcDate: string): string {
        return moment.parseZone(utcDate).local().format(this.shortDateWithFullYearFormat);
    }
}
