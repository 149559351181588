import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { HomeComponent } from './home/home.component';
import { AuthGuard } from './shared/core/auth/auth.guard';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'error', loadChildren: () =>
                    import('./modules/errors/error-page.module')
                        .then(({ ErrorPageModule }) => ErrorPageModule)
            },
            { path: 'uct-credit-transactions', canLoad: [AuthGuard], loadChildren: () =>
                    import('./modules/uct-credit-transactions/uct-credit-transactions.module')
                        .then(({ UctCreditTransactionsModule }) => UctCreditTransactionsModule),
            },
            { path: 'master-data-management', canLoad: [AuthGuard], loadChildren: () =>
                    import('./modules/data-management/data-management.module')
                        .then(({ DataManagementModule }) => DataManagementModule ),
            },
            { path: 'reports', canLoad: [AuthGuard], loadChildren: () =>
                    import('./modules/reports/reports.module')
                        .then(({ ReportsModule }) => ReportsModule ),
            },
            { path: 'users', canLoad: [AuthGuard], loadChildren: () =>
                    import('./modules/users/users.module')
                        .then(({ UsersModule }) => UsersModule ),
            },
            { path: 'self-credit-transactions', canLoad: [AuthGuard], loadChildren: () =>
                    import('./modules/self-credit-transactions/self-credit-transactions.module')
                        .then(({ SelfCreditTransactionsModule }) => SelfCreditTransactionsModule ),
            },
        ]
    },
    { path: 'login', component: LoginComponent },
    { path: '**', redirectTo: 'error/404' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
