import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app.routing.module';
import { LoginComponent } from './components/login/login.component';
import { MaterialModule } from './material.module';
import { AuthService } from './auth/auth.service';
import { HttpService } from './services/http.service';
import { AuthGuard } from './shared/core/auth/auth.guard';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { SharedModule } from './shared/shared.module';
import { jwtHttpInterceptorProviders } from './auth/token.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StorageManagementService } from './services/storage-management.service';
import { QueryBuilderService } from './services/query-builder.service';
import { AppNotificationsModule } from './modules/app-notifications-module/app-notifications.module';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        AppHeaderComponent,
        AppFooterComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        ReactiveFormsModule,
        SharedModule,
        MatProgressSpinnerModule,
        AppNotificationsModule,
    ],
    providers: [
        AuthService,
        HttpService,
        AuthGuard,
        jwtHttpInterceptorProviders,
        StorageManagementService,
        QueryBuilderService
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
