export class MoneyHelper {
    static formatNumberWithThousandSeparator(value: number, separator = '.')
    {
        return value?.toString()?.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    }

    static formatNumberWithoutSeparator(value: number)
    {
        return value.toString().replace('.', ',');
    }

    static round(value: number, precision: number = 2): number {
        const roundingFactor = Math.pow(10, precision);

        return Math.round(value * roundingFactor) / roundingFactor;
    }
}
