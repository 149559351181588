import { Injectable } from '@angular/core';

import { CountedValuesModel } from '../../../models/counted-values/counted-values.model';
import { CountedValuesTableModel } from '../../../models/counted-values/counted-values-table.model';
import { DatetimeHelper } from '../helpers/datetime.helper';
import { MoneyHelper } from '../helpers/money-helper';
import { CountedValuesStatusType } from '../../enums/counted-values.enum';
import { CreditType } from '../../enums/credit-type.enum';

@Injectable()
export class CountedValuesMappingService {

    constructor() {
    }

    public CountedValuesMappingForTable(data: CountedValuesModel[]): CountedValuesTableModel[] {
        return data.map(element => {
            return {
                id: element.id,
                bagLabelCode: element.bagLabelCode,
                cpId: element?.cpId,
                showCpidWarning: !element?.cpId,
                locationName: element.location?.name ? element.location.name : element.location.id,
                showLocationWarning: !element.location?.name,
                clientName: element.clientName,
                showClientWarning: !element.clientName,
                mpAmount: MoneyHelper.formatNumberWithThousandSeparator(element.mpAmount),
                declaredAmount: this.getDeclaredAmount(element),
                processingDate: DatetimeHelper.getLocalDateWithTime(element.processingDate),
                countedValueStatusType: element.countedValueStatusType,
                viewActions: element.countedValueStatusType === CountedValuesStatusType.Difference || (element.countedValueStatusType === CountedValuesStatusType.NotMatched && element.creditType === CreditType.Location),
                isNegative: element.countedValueStatusType === CountedValuesStatusType.Difference && element.declaredAmount > element.mpAmount,
                isPositive: element.countedValueStatusType === CountedValuesStatusType.Difference && element.declaredAmount < element.mpAmount,
                CCY: 'RON'
            };
        });
    }

    public getDeclaredAmount(element) {
        if(element.countedValueStatusType === CountedValuesStatusType.NotMatched || !element.declaredAmount) {
            return 0;
        } else if(element.countedValueStatusType === CountedValuesStatusType.Error || !element.mpAmount) {
            return MoneyHelper.formatNumberWithThousandSeparator(element.declaredAmount);
        } else if(element.countedValueStatusType === CountedValuesStatusType.Difference) {
            return MoneyHelper.formatNumberWithThousandSeparator(element.declaredAmount) +
                ` (${element.declaredAmount < element.mpAmount ?
                    '+' + MoneyHelper.formatNumberWithThousandSeparator(MoneyHelper.round(element.mpAmount - element.declaredAmount))
                    : MoneyHelper.formatNumberWithThousandSeparator(MoneyHelper.round(element.mpAmount - element.declaredAmount))})`;
        } else {
            return 0;
        }
    }
}
