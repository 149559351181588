import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { catchError, EMPTY, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(
        private http: HttpClient,
        private router: Router,
    ) {
    }

    get<T>(url: string, httpOptions?: object, useCustomHandleError = false): Observable<T> {
        return this.http.get<any>(url, httpOptions).pipe(catchError(er => this.handleError(er, useCustomHandleError)));
    }

    post<T>(url: string, body: object, httpOptions?: object, useCustomHandleError = false): Observable<T> {
        return this.http.post<any>(url, body, httpOptions).pipe(catchError(er => this.handleError(er, useCustomHandleError)));
    }

    put<T>(url: string, body: object, httpOptions?: object, useCustomHandleError = false): Observable<T> {
        return this.http.put<any>(url, body, httpOptions).pipe(catchError(er => this.handleError(er, useCustomHandleError)));
    }

    patch<T>(url: string, body: object, httpOptions?: object, useCustomHandleError = false): Observable<T> {
        return this.http.patch<any>(url, body, httpOptions).pipe(catchError(er => this.handleError(er, useCustomHandleError)));
    }

    delete<T>(url: string, httpOptions?: object, useCustomHandleError = false): Observable<T> {
        return this.http.delete<any>(url, httpOptions).pipe(catchError(er => this.handleError(er, useCustomHandleError)));
    }

    handleError(error: HttpErrorResponse, useCustomHandleError = false): Observable<never> {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}`, error.error);
        }

        if (error.status === HttpStatusCode.Unauthorized) {
            this.router.navigate(['login']);

            return EMPTY;
        } else if (error.status === HttpStatusCode.InternalServerError && !useCustomHandleError) {
            this.router.navigate(['error', HttpStatusCode.InternalServerError]);

            return EMPTY;
        } else if (error.status === HttpStatusCode.BadRequest && !useCustomHandleError) {
            this.router.navigate(['error', HttpStatusCode.InternalServerError]);

            return EMPTY;
        } else if (error.status === HttpStatusCode.Forbidden && !useCustomHandleError) {
            this.router.navigate(['error', HttpStatusCode.Forbidden]);

            return EMPTY;
        } else if (error.status === HttpStatusCode.NotFound && !useCustomHandleError) {
            this.router.navigate(['error', HttpStatusCode.NotFound]);

            return EMPTY;
        }

        throw error;
    }
}
