import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { firstTab, navRoutes } from '../shared/core/consts/nav-routes.const';
import { AuthHelper } from '../shared/core/helpers/auth.helper';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{

    constructor(
        public router: Router
    ) {
    }
    ngOnInit() {
        window.location.pathname === '/' ?
            this.router.navigate([this.getRoute()]) : null;
    }

    getRoute() {
        return navRoutes.find(el =>
            el.id === firstTab[AuthHelper.getTokenRole(AuthHelper.getToken())]
        )?.link || 'reports';
    }
}
