import { Injectable } from '@angular/core';

import { DatetimeHelper } from '../helpers/datetime.helper';
import { PaymentFileRecordModel } from '../../../models/payment-file-record/payment-file-record.model';
import { PaymentFileRecordTableModel } from '../../../models/payment-file-record/payment-file-record-table.model';
import { MoneyHelper } from '../helpers/money-helper';
import {
    PaymentCommandTransactionViewModel
} from '../../../models/payment-file-record-details/payment-file-record-details.model';
import {
    PaymentCommandTransactionTableModel
} from '../../../models/payment-file-record-details/payment-file-record-details-table.model';
import { TransactionSourceType } from '../../enums/transactions.enum';

@Injectable()
export class PaymentFileRecordDetailsMappingService {

    public PaymentFileRecordDetailsMappingService(data: PaymentCommandTransactionViewModel[]): PaymentCommandTransactionTableModel[] {
        return data.map(element => {
            return {
                depositDate: element.depositDate,
                cpId: element.cpId,
                postedAmount: element.source === TransactionSourceType.FromDifference && element.postedAmount > 0 ?
                    `+${element.postedAmount}` : element.postedAmount.toString(),
                CCY: 'RON',
                bagLabelCode: element.bagLabelCode,
                source: element.source,
                isPositive: element.source === TransactionSourceType.FromDifference && element.postedAmount > 0,
                isNegative: element.source === TransactionSourceType.FromDifference && element.postedAmount < 0,
            };
        });
    }
}
