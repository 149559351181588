import { Injectable } from '@angular/core';
import { defaultPageSize } from '../shared/core/consts/page-size.const';
import { FilterSettingsModel } from '../models/page-setting.model';

@Injectable()
export class StorageManagementService {
    public getSortDirection(nameOfType: string): 0 | 1 | null {
        return JSON.parse(localStorage.getItem(`v2-${nameOfType}SortDirection`));
    }

    public getSortedProperty(nameOfType: string): string {
        return localStorage.getItem(`v2-${nameOfType}SortedProperty`);
    }

    public getPageSize(nameOfType: string): number {
        return Number(localStorage.getItem(`v2-${nameOfType}PageSize`)) || defaultPageSize;
    }

    public getPageIndex(nameOfType: string): number {
        return Number(localStorage.getItem(`v2-${nameOfType}PageIndex`)) || 0;
    }

    public getFilters(nameOfType: string): FilterSettingsModel[] {
        return JSON.parse(localStorage.getItem(`v2-${nameOfType}FilterSettings`)) || [];
    }

    public setSortDirection(nameOfType: string, value: 0 | 1 | null): void {
        if(value === null) {
            localStorage.removeItem(`v2-${nameOfType}SortDirection`);
        }
        else {
            localStorage.setItem(`v2-${nameOfType}SortDirection`, JSON.stringify(value));
        }
    }

    public setSortedProperty(nameOfType: string, value: string): void {
        if(!value) {
            localStorage.removeItem(`v2-${nameOfType}SortedProperty`);
        }
        else {
            localStorage.setItem(`v2-${nameOfType}SortedProperty`, value);
        }
    }

    public setPageSize(nameOfType: string, value: number): void {
        if(!value) {
            localStorage.removeItem(`v2-${nameOfType}PageSize`);
        }
        else {
            localStorage.setItem(`v2-${nameOfType}PageSize`, value.toString());
        }
    }

    public setPageIndex(nameOfType: string, value: number): void {
        if(!value) {
            localStorage.removeItem(`v2-${nameOfType}PageIndex`);
        }
        else {
            localStorage.setItem(`v2-${nameOfType}PageIndex`, value.toString());
        }
    }

    public setFilters(nameOfType: string, value: FilterSettingsModel[]): void {
        if(!value) {
            localStorage.removeItem(`v2-${nameOfType}FilterSettings`);
        }
        else {
            localStorage.setItem(`v2-${nameOfType}FilterSettings`, JSON.stringify(value));
        }
    }
}
