import { environment } from '../../../../environments/environment';

const serverUrl = environment.apiUrl;

export const apiUrlConsts = {
    test: serverUrl + '/test',
    version: serverUrl + '/configuration/build-version',
    organizations: serverUrl + '/organizations',
    banks: serverUrl + '/organizations/{0}/banks',
    bank: serverUrl + '/banks',
    cashProcessings: serverUrl + '/organizations/{0}/cash-processings',
    cashProcessing: serverUrl + '/cash-processings',
    clients: serverUrl + '/banks/{0}/clients',
    client: serverUrl + '/clients',
    location: serverUrl + '/locations',
    locations: serverUrl + '/clients/{0}/locations',
    depositClientUsers: serverUrl + '/clients/{0}/deposit-client-users',
    depositClientUser: serverUrl + '/deposit-client-users',
    transactions: serverUrl + '/transactions',
    updateTransactionIsArchived: serverUrl + '/transactions/is-archived',
    exportToPaymentFile: serverUrl + '/transactions/payment-file',
    paymentCommands: serverUrl + '/payment-commands',
    bankStatements: serverUrl + '/bank-statements',
    updateBankStatementIsArchived: serverUrl + '/bank-statements/is-archived',
    match: serverUrl + '/bank-statements/match',
    manualMatch: serverUrl + '/bank-statements/{0}/match',
    paymentFileRecord: serverUrl + '/paymentFile',
    countedValues: serverUrl + '/counted-values',
    matchCountedValues: serverUrl + '/counted-values/match',
    forceMatchCountedValues: serverUrl + '/counted-values/force-match',
    toBeCounted: serverUrl + '/to-be-counted',
    importBankStatementFile: serverUrl + '/bank-statements/import/{0}',
    historySync: serverUrl + '/data-sync-history/',
    reports: serverUrl + '/reports',
    users: serverUrl + '/users',
    masterDataSync: serverUrl + '/master-data/sync',
    groupDifferences: serverUrl + '/group-differences',
    login: serverUrl + '/api/login',
    ssoSignIn: serverUrl + '/api/login/ssosignin',
    ssoSignOut: serverUrl + '/api/login/ssosignout',
    transactionCountedValueMatch: serverUrl + '/transaction-counted-value-match',
    matchGroupDifferences: serverUrl + '/group-differences/process-match',
};
