export enum CountedValuesStatusType {
    NotMatched = 1,
    Error,
    Difference,
    New,
    BsMatched
}
export enum CountedValuesDeclaredAmountType {
    Pending = 0
}

export const CountedValuesStatusTypeUiText = {
    [1]: 'Not Matched',
    [2]: 'Error',
    [3]: 'Difference',
    [4]: 'New',
    [5]: 'BS Matched',
};
export const CountedValuesDeclaredAmountTypeUiText = {
    [0]: 'Pending',
};
