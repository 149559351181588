<div class="login-page">
    <div class="login-container">
        <div class="image-container">
            <img class="logo-image" src="/assets/images/icons/logo.svg" alt="" />
        </div>

        <span [hidden]="!errorSingle" class="alert">{{ errorSingle }}</span>

        <button mat-button class="sso-button" (click)="ssoLogin()">
            Sign in with SSO
        </button>

        <form *ngIf="showLoginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()" id="loginForm" >
            <mat-form-field appearance="outline">
                <input matInput formControlName="email" type="text" placeholder="Login" />
                <mat-error>
                    <ng-container *ngIf="getErrors('email')" class="invalid-feedback">Enter your email</ng-container>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <input matInput formControlName="password" type="password" placeholder="Password" />
                <mat-error>
                    <ng-container *ngIf="getErrors('password')" class="invalid-feedback">Enter your Password</ng-container>
                </mat-error>
            </mat-form-field>
            <div id="btnContainer">
                <button mat-button type="submit" id="loginBtn">
                <span class="spinner-success-container" *ngIf="loginInProgress; else successButtonText">
                <mat-spinner diameter="16"></mat-spinner>
            </span>
                </button>
                <ng-template #successButtonText>Log in</ng-template>
            </div>
        </form>
    </div>
</div>
