import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[text-tooltip]'
})
export class TextTooltipDirective {
    @Input() padding = 0;

    @Output() tooltipEvent = new EventEmitter<boolean>();

    constructor(private el: ElementRef) {}

    @HostListener('mouseenter')
    onHover() {
        this.setIsTooltipDisabled();
    }

    setIsTooltipDisabled() {
        const nativeElement = this.el.nativeElement;
        const isTooltipDisabled = this.el.nativeElement.scrollWidth <= nativeElement.parentElement?.clientWidth - this.padding;

        this.tooltipEvent.emit(isTooltipDisabled);
    }
}
