export const userRoles = [
    {
        id: 'Admin',
        name: 'Admin'
    },
    {
        id: 'FinanceOfficer',
        name: 'Finance Officer'
    },
    {
        id: 'OpsReconciliationOfficer',
        name: 'Ops Reconciliation Officer'
    },
    /*{
        id: 'OrderOfficer',
        name: 'Order Officer'
    },*/
    {
        id: 'Viewer',
        name: 'Viewer'
    },
];
