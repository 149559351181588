import { Component, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { navRoutes } from '../../shared/core/consts/nav-routes.const';
import { apiUrlConsts } from '../../shared/core/consts/api-url.const';
import { AuthHelper } from '../../shared/core/helpers/auth.helper';
import { UserProfileModel } from 'src/app/models/userProfile.model';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit{

    @Output() innerClass = 'justify-content-end';
    @Output() routes;

    tooltipDisabled = false;
    userProfile: UserProfileModel;

    constructor(
        public dialog: MatDialog,
        public router: Router
    ) {
    }

    ngOnInit() {
        this.routes = navRoutes.filter(el =>
            AuthHelper.getRoutesForRole()?.includes(el.id)
        );

        this.loadUserProfile();
    }

    private loadUserProfile(): void {
        this.userProfile = AuthHelper.getUserProfile();
    }

    logOut(): void {
        const isSso = AuthHelper.isSso();

        if (isSso) {
            AuthHelper.removeToken();
            window.location.href = apiUrlConsts.ssoSignOut;
        } else {
            this.router.navigate(['login']);
        }
    }
}
