import { Injectable } from '@angular/core';

import { DatetimeHelper } from '../helpers/datetime.helper';
import { TransactionCountedValueMatchModel } from '../../../models/transaction-counted-value-match/transaction-counted-value-match.model';
import { MoneyHelper } from '../helpers/money-helper';
import { TransactionCountedValueMatchTableModel } from '../../../models/transaction-counted-value-match/transaction-counted-value-match-table.model';
import { TransactionCountedValueMatchHelper } from '../helpers/transaction-counted-value-match.helper';

@Injectable()
export class TransactionCountedValueMatchMappingService {

    constructor() {
    }

    public TransactionCountedValueMatchMappingForTable(data: TransactionCountedValueMatchModel[]): TransactionCountedValueMatchTableModel[] {
        return data.map(element => {
            return {
                id: element.id,
                bagLabelCode: element.bagLabelCode,
                clientName: element.clientName,
                locationName: element.locationName,
                userName: element.userName,
                postedAmount: TransactionCountedValueMatchHelper.getPostedAmount(element),
                mpAmount: MoneyHelper.formatNumberWithThousandSeparator(element.mpAmount),
                depositDate: DatetimeHelper.getLocalDateWithTime(element.depositDate),
                processingDate: DatetimeHelper.getLocalDateWithTime(element.processingDate),
                status: element.status,
                CCY: 'RON',
                isPositive: TransactionCountedValueMatchHelper.isPositive(element),
                isNegative: TransactionCountedValueMatchHelper.isNegative(element),
                cpId: element?.cpId,
                showCpidWarning: !element?.cpId,
               };
        });
    }
}
