export enum TransactionStatusType {
    Undefined,
    New,
    Error,
    Blocked,
    Canceled,
    Exported,
    NotMatched,
    Difference,
    Positive,
    Negative,
    Reconciled,
    NewAfterMp,
    Counted,
    BsMatched
}

export enum TransactionSourceType {
    Declared = 1,
    Counted,
    FromDifference
}

export const TransactionStatusTypeUiText = {
    [0]: 'Undefined',
    [1]: 'New',
    [2]: 'Error',
    [3]: 'Blocked',
    [4]: 'Canceled',
    [5]: 'Exported',
    [6]: 'Not Matched',
    [7]: 'Difference',
    [8]: 'Positive',
    [9]: 'Negative',
    [10]: 'Reconciled',
    [11]: 'New After MP',
    [12]: 'New After MP',
    [13]: 'BS Matched',
};

export const TransactionSourceUiText = {
    [1]: 'Declared',
    [2]: 'Counted',
};
