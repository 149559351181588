import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppNotificationSuccessComponent } from './app-notification-success.component';
import { MaterialModule } from '../../material.module';

@NgModule({
  declarations: [AppNotificationSuccessComponent],
  imports: [BrowserModule, MaterialModule],
  exports: [],
  entryComponents: [AppNotificationSuccessComponent],
  providers: [],
  bootstrap: [],
})
export class AppNotificationsModule {}
