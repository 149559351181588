export {};

String.prototype.removeExtraSpaces = function (): string {
    return this.replace(/\s+/g, ' ').trim();
};

String.prototype.formatString = function (...replacements: string[]): string {
    return this.replace(/{(\d+)}/g, (match, number) => {
        return replacements[number] != null
            ? replacements[number]
            : match;
    });
};
