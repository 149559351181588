import { Injectable } from '@angular/core';

import { DatetimeHelper } from '../helpers/datetime.helper';
import { PaymentFileRecordModel } from '../../../models/payment-file-record/payment-file-record.model';
import { PaymentFileRecordTableModel } from '../../../models/payment-file-record/payment-file-record-table.model';
import { MoneyHelper } from '../helpers/money-helper';

@Injectable()
export class PaymentFileRecordMappingService {

    public PaymentFileRecordMappingForTable(data: PaymentFileRecordModel[]): PaymentFileRecordTableModel[] {
        return data.map(element => {
            return {
                id: element.id,
                paymentFileId: element.paymentFileId,
                clientName: element.clientName,
                locationName: element.locationName,
                userName: element.userName,
                creditDate: DatetimeHelper.getLocalDateWithTime(element?.creditDate),
                depositDate: DatetimeHelper.getLocalDateWithTime(element?.depositDate),
                cpId: element?.cpId,
                postedAmount: MoneyHelper.formatNumberWithThousandSeparator(element.postedAmount),
                viewActions: true,
                CCY: 'RON'
            };
        });
    }
}
