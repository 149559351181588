import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class MaxContentHeightService {
    private maxContentHeightChangedSubject = new Subject<number>();

    maxContentHeightChanged$ = this.maxContentHeightChangedSubject.asObservable();

    recalculateMaxContentHeight(): void {
        this.maxContentHeightChangedSubject.next(this.getStickyElementsHeight());
    }

    getStickyElementsHeight(): number {
        const elements = document.getElementsByClassName('sticky-page-content');

        let sum = 0;

        for (let i = 0; i < elements.length; i++) {
            sum += elements[i].clientHeight;
        }

        return sum;
    }
}
