import { TransactionCountedValueMatchType } from '../../enums/transaction-counted-value-match.enum';
import { MoneyHelper} from './money-helper';
import { TransactionCountedValueMatchModel } from '../../../models/transaction-counted-value-match/transaction-counted-value-match.model';

export class TransactionCountedValueMatchHelper {
    static isPositive(element): boolean {
        return element.status === TransactionCountedValueMatchType.Difference && element.postedAmount < element.mpAmount;
    }

    static isNegative(element): boolean {
        return element.status === TransactionCountedValueMatchType.Difference && element.postedAmount > element.mpAmount;
    }

    static getPostedAmount(transaction: TransactionCountedValueMatchModel): string {
        return transaction.status === TransactionCountedValueMatchType.BsMatched ?
            MoneyHelper.formatNumberWithThousandSeparator(transaction.postedAmount)
            : this.isNegative(transaction) ?
                (MoneyHelper.formatNumberWithThousandSeparator(transaction.postedAmount) + ' (' + MoneyHelper.formatNumberWithThousandSeparator(MoneyHelper.round(transaction.mpAmount - transaction.postedAmount)) + ')')
                : this.isPositive(transaction) ?
                    (MoneyHelper.formatNumberWithThousandSeparator(transaction.postedAmount) + ' (+' + MoneyHelper.formatNumberWithThousandSeparator(MoneyHelper.round(transaction.mpAmount - transaction.postedAmount)) + ')')
                    : MoneyHelper.formatNumberWithThousandSeparator(transaction.postedAmount);
    }
}
