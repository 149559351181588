import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { finalize } from 'rxjs';
import { apiUrlConsts } from '../../shared/core/consts/api-url.const';
import { environment } from '../../../environments/environment';
import { AuthHelper } from '../../shared/core/helpers/auth.helper';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    public loginInProgress = false;
    public loginForm: FormGroup;
    public errorSingle = '';
    public showLoginForm = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private authService: AuthService
    ) {
        this.showLoginForm = !environment.production;
    }

    ngOnInit() {
        AuthHelper.removeToken();
        this.initForm();

        if (this.route.snapshot.fragment) {
            const error = new URLSearchParams(this.route.snapshot.fragment).get('error');
            if (error) {
                this.errorSingle = error;
            }

            const token = new URLSearchParams(this.route.snapshot.fragment).get('token');
            if (token) {
                AuthHelper.ssoSignIn(token);
                this.router.navigate(['']);
            }
        }
    }

    initForm() {
        if (this.showLoginForm) {
            this.loginForm = this.formBuilder.group({
                email: new FormControl('', [Validators.required]),
                password: new FormControl('', [Validators.required])
            });
        }
    }

    getErrors(input) {
        return this.loginForm.get(input).errors;
    }

    onSubmit() {
        this.loginForm.markAllAsTouched();

        if (this.loginForm.invalid || this.loginInProgress) {
            return;
        }

        this.loginInProgress = true;

        this.authService.login(JSON.stringify(this.loginForm.getRawValue()))
            .pipe(finalize(() => this.loginInProgress = false))
            .subscribe(data => {
                    AuthHelper.setToken(data.token);
                    this.router.navigate(['']);
                },
                error => alert(error.error ?? 'Something went wrong'));
    }

    ssoLogin(): void {
        window.location.href = apiUrlConsts.ssoSignIn;
    }
}
