import { Injectable } from '@angular/core';
import { CanActivate, Route, Router } from '@angular/router';

import { navRoutes } from '../consts/nav-routes.const';
import { AuthHelper } from '../helpers/auth.helper';

@Injectable({
    providedIn: 'root',
})

export class AuthGuard implements CanActivate {

    constructor(
        private router: Router
    ) {
    }

    canActivate() {
        if (AuthHelper.getToken() && AuthHelper.getTokenRole(AuthHelper.getToken())) {
            return true;
        } else {
            this.router.navigate(['login']);
            return false;
        }
    }

    canLoad(route: Route) {
        if(!AuthHelper.getToken()) {
            this.router.navigate(['login']);
            return false;
        }
        if(AuthHelper.getRoutesForRole()?.includes(navRoutes.find(el => route.path.includes(el.link.slice(1)))?.id)) {
            return true;
        } else {
            this.router.navigate(['error', 403]);
            return false;
        }
    }
}
