<div id="header" class="sticky-page-content">
    <div id="headerContainer" class="flexContainer">
        <div class="imageContainer">
            <img id="logoImage" src="/assets/images/icons/logo.svg" alt=""/>
        </div>
        <div id="navBarContainer" class="flexContainer">
            <app-nav-menu [routes]="routes"></app-nav-menu>
            <div id="logoutContainer" class="imageContainer">
                <button mat-icon-button [matMenuTriggerFor]="menu" id="logoutBtn">
                    <img id="logoutImage" src="/assets/images/icons/logout.svg" alt=""/>
                </button>
                <mat-menu #menu="matMenu">
                    <div mat-menu-item
                         class="no-pointer-events full-name-info">
                        <img src="/assets/images/icons/account.svg" alt=""/>
                        <div text-tooltip
                              [matTooltip]="userProfile.fullName"
                              [matTooltipDisabled]="tooltipDisabled"
                              (tooltipEvent)="tooltipDisabled = $event"
                              class="text-overflow max-width-220 username-line-height">{{userProfile.fullName}}</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div mat-menu-item class="infoBox no-pointer-events">
                        <label>User Role</label>
                        <div text-tooltip
                             [matTooltip]="userProfile.roleName"
                             [matTooltipDisabled]="tooltipDisabled"
                             (tooltipEvent)="tooltipDisabled = $event">{{userProfile.roleName}}</div>
                    </div>
                    <div mat-menu-item class="infoBox no-pointer-events ">
                        <label>Organization</label>
                        <div text-tooltip
                             [matTooltip]="userProfile.organizationName"
                             [matTooltipDisabled]="tooltipDisabled"
                             (tooltipEvent)="tooltipDisabled = $event"
                             class="text-overflow max-width-250">{{userProfile.organizationName}}</div>
                    </div>
                    <mat-divider></mat-divider>
                    <button mat-menu-item (click)="logOut()">
                        <div class="logout-line-height">Log out</div>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>
