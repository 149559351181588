import { Injectable } from '@angular/core';
import { ReconciliationType } from '../shared/enums/reconciliation-type.enum';
import { TransactionStatusType } from '../shared/enums/transactions.enum';
import { TransactionCountedValueMatchType } from '../shared/enums/transaction-counted-value-match.enum';
import { CountedValuesStatusType } from '../shared/enums/counted-values.enum';
import { FilterSettingsModel } from '../models/page-setting.model';
import { OperatorEnum } from '../shared/enums/operator.enum';

@Injectable()
export class QueryBuilderService {
    createReconciliationTypeFilter(type: ReconciliationType, fieldName: string, includeNull = true): FilterSettingsModel {
        return {
            fieldName: fieldName,
            value: type.toString(),
            isDefaultPageFilter: true,
            comparisonOperator: OperatorEnum.Equal,
            isFromFilter: false,
            logicalOrPageFilterSettings: includeNull ? [{
                fieldName: fieldName,
                isDefaultPageFilter: true,
                value: null,
                comparisonOperator: OperatorEnum.Equal,
                isFromFilter: false
            }] : null
        };
    }

    createStatusesFilter(statuses: number[], fieldName = 'status'): FilterSettingsModel {
        let filter = {
            fieldName: fieldName,
            isDefaultPageFilter: true,
            value: statuses.shift().toString(),
            comparisonOperator: OperatorEnum.Equal,
            logicalOrPageFilterSettings: [],
            isFromFilter: false
        };
        statuses.forEach(el => filter.logicalOrPageFilterSettings.push({
            fieldName: fieldName,
            isDefaultPageFilter: true,
            value: el.toString(),
            comparisonOperator: OperatorEnum.Equal,
        }));
        return filter;
    }
}
