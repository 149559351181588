import { Injectable } from '@angular/core';

import { BankStatementModel } from '../../../models/bank-statement/bank-statement.model';
import { BankStatementTableModel } from '../../../models/bank-statement/bank-statement-table.model';
import { DatetimeHelper } from '../helpers/datetime.helper';
import { MoneyHelper } from '../helpers/money-helper';

@Injectable()
export class BankStatementMappingService {

    constructor() {
    }

    public BankStatementMappingForTable(data: BankStatementModel[]): BankStatementTableModel[] {
        return data.map(element => {
            return {
                id: element.id,
                operationDate: DatetimeHelper.getShortLocalDate(element.operationDate),
                clientName: element.clientName,
                locationName: element.locationName,
                amountDB: MoneyHelper.formatNumberWithThousandSeparator(element.amountDB),
                code: element.code,
                iban: element.iban,
                details: element.details,
                viewActions: true,
                type: element?.type,
                CCY: 'RON'
            };
        });
    }
}
