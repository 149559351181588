import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { apiUrlConsts } from '../../shared/core/consts/api-url.const';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit{

    public version;

    constructor(
        public httpService: HttpService
    ) {}

    ngOnInit() {
        this.httpService.get(apiUrlConsts.version).subscribe(data => this.version = data);
    }
}
