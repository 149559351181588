import { MoneyHelper } from './money-helper';
import { GroupedDifferenceDetailsModel } from '../../../models/grouped-differences/grouped-differences.model';

export class GroupedDifferencesHelper {

    static getPostedAmount(transaction: GroupedDifferenceDetailsModel): string {
        return transaction.postedAmount ?
                MoneyHelper.formatNumberWithThousandSeparator(transaction.postedAmount) +
            (transaction.mpAmount > transaction.postedAmount ?
                    ' (+' + MoneyHelper.formatNumberWithThousandSeparator(MoneyHelper.round(transaction.mpAmount - transaction.postedAmount)) + ')'
                    : ' (' + MoneyHelper.formatNumberWithThousandSeparator(MoneyHelper.round(transaction.mpAmount - transaction.postedAmount)) + ')')
                : MoneyHelper.formatNumberWithThousandSeparator(transaction.postedAmount);
    }

}
