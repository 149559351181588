import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '../services/http.service';
import { apiUrlConsts } from '../shared/core/consts/api-url.const';

@Injectable()
export class AuthService {
    private contentHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(
        private httpService: HttpService,
    ) {
    }

    public login(data): Observable<{token: string}> {
        return this.httpService.post(apiUrlConsts.login, data, { headers: this.contentHeaders }, true);
    }
}
