import { Injectable } from '@angular/core';

import { DatetimeHelper } from '../helpers/datetime.helper';
import {
    GroupedDifferenceDetailsModel,
    GroupedDifferencesModel,
} from '../../../models/grouped-differences/grouped-differences.model';
import {
    GroupedDifferenceDetailsTableModel,
    GroupedDifferencesTableModel
} from '../../../models/grouped-differences/grouped-differences-table.model';
import { MoneyHelper } from '../helpers/money-helper';
import { GroupedDifferencesHelper } from '../helpers/grouped-differences.helper';

@Injectable()
export class GroupedDifferencesMappingService {

    constructor() {
    }

    public GroupedDifferencesMappingForTable(data: GroupedDifferencesModel[]): GroupedDifferencesTableModel[] {
        return data.map(element => {
            return {
                id: element.id,
                clientName: element.clientName,
                locationName: element.locationName,
                amount: (element.amount > 0 ? '+' : '') + MoneyHelper.formatNumberWithThousandSeparator(element.amount),
                processingDate: DatetimeHelper.getLocalDateWithTime(element.processingDate),
                status: element.status,
                CCY: 'RON',
            };
        });
    }

    public GroupedDifferencesDetailsMappingForTable(data: GroupedDifferenceDetailsModel[]): GroupedDifferenceDetailsTableModel[] {
        return data.map(element => {
            return {
                bagLabelCode: element.bagLabelCode,
                userName: element.userName,
                mpAmount: MoneyHelper.formatNumberWithThousandSeparator(element.mpAmount),
                postedAmount: GroupedDifferencesHelper.getPostedAmount(element),
                depositDate: DatetimeHelper.getLocalDateWithTime(element.depositDate),
                clientName: element.clientName,
                locationName: element.locationName,
                processingDate: DatetimeHelper.getLocalDateWithTime(element.processingDate),
                cpId: element?.cpId,
                showCpidWarning: !element?.cpId,
                CCY: 'RON',
            };
        });
    }
}
