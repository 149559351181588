export {};
Array.prototype.insert = function (index, items) {
    this.splice.apply(this, [index, 0].concat(items));
};

Array.prototype.sum = function () {
    return this.reduce((a, b) => a + b, 0);
};

Array.prototype.sumBy = function (prop) {
    let total = null;

    for (const elem of this) {
        const value = elem[prop];

        if (value != null) {
            total += value;
        }
    }

    return total;
};

Array.prototype.getByProperty = function (prop, value) {
    return this.find(x => x[prop] === value);
};

Array.prototype.first = function () {
    return this != null ? this[0] : null;
};

Array.prototype.last = function () {
    return this != null ? this[this.length - 1] : null;
};

Array.prototype.groupBy = function (key) {
    return this.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );
};

Array.prototype.sortBy = function (prop) {
    return this.sort((a, b) => (a[prop] > b[prop]) ? 1 : ((b[prop] > a[prop]) ? -1 : 0));
};
