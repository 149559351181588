import { AuthConstantConfig } from '../auth/auth-constant.config';
import { roleToRoutes } from '../consts/nav-routes.const';
import { UserProfileModel } from 'src/app/models/userProfile.model';
import { userRoles } from '../consts/user-roles.const';
import jwt_decode from 'jwt-decode';

export class AuthHelper {
    private static tokenName = AuthConstantConfig.tokenName;
    private static isSsoName = AuthConstantConfig.isSsoName;

    static ssoSignIn(token: string): void {
        this.setIsSso(true.toString());
        this.setToken(token);
    }

    static getToken(): string {
        return localStorage.getItem(this.tokenName);
    }

    static setToken(token): void {
        localStorage.setItem(this.tokenName, token);
    }

    static setIsSso(isSso): void {
        localStorage.setItem(this.isSsoName, isSso);
    }

    static isSso(): boolean {
        return localStorage.getItem(this.isSsoName) === true.toString();
    }

    static removeToken(): void {
        localStorage.removeItem(AuthConstantConfig.tokenName);
        localStorage.removeItem(AuthConstantConfig.isSsoName);
    }

    static getRoutesForRole() {
        return roleToRoutes[this.getTokenRole(this.getToken())];
    }

    static getTokenRole(token): string {
        return this.processToken(token);
    }

    static isAdmin(): boolean {
        return this.getTokenRole(this.getToken()) === 'Admin';
    }

    static getUserProfile(): UserProfileModel {
        let decodedToken = jwt_decode(this.getToken());

        let userProfile = new UserProfileModel();

        userProfile.fullName = decodedToken[AuthConstantConfig.userFullName];
        userProfile.email = decodedToken[AuthConstantConfig.userEmail];
        userProfile.organizationName = decodedToken[AuthConstantConfig.organizationName];
        userProfile.roleName = userRoles.find(r => r.id === decodedToken[AuthConstantConfig.userRoles])?.name;

        return userProfile;
    }

    private static processToken(token): string {
        return jwt_decode(token)[AuthConstantConfig.userRoles];
    }
}
