import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthHelper } from '../shared/core/helpers/auth.helper';

@Injectable({
    providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = this.addTokenHeader(request, AuthHelper.getToken());
        return next.handle(authReq).pipe(
            catchError(errorData => {
                return throwError(errorData);
            })
        );
    }

    addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }
}

export const jwtHttpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }];
