export const navRoutes = [
    {
        id: 1,
        text: 'Self Credit Transactions',
        link: '/self-credit-transactions'
    },
    {
        id: 3,
        text: 'UCT Credit Transactions',
        link: '/uct-credit-transactions'
    },
    {
        id: 4,
        text: 'Master Data Management',
        link: '/master-data-management'
    },
    {
        id: 5,
        text: 'User Management',
        link: '/users'
    },
    {
        id: 6,
        text: 'Reports',
        link: '/reports'
    }
];

export const roleToRoutes = {
    'FinanceOfficer': [1, 3, 6],
    'OpsReconciliationOfficer': [1, 3, 6],
    'Admin': [1, 3, 4, 5, 6],
    'Viewer': [6]
};

export const firstTab = {
    'FinanceOfficer': 1,
    'OpsReconciliationOfficer': 3,
    'Admin': 6,
    'Viewer': 6
};
