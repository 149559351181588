import { tooltipsConst } from '../consts/tooltips.const';
import { TransactionModel } from '../../../models/transactions/transaction.model';
import { ReconciliationType } from '../../enums/reconciliation-type.enum';
import {
    AssignedTransactionTableModel,
    TransactionTableModel
} from '../../../models/transactions/transactions-table.model';
import { TransactionSourceType, TransactionStatusType } from '../../enums/transactions.enum';
import { MoneyHelper } from './money-helper';

export class TransactionHelper {
    static showClientWarning(transaction: TransactionModel): boolean {
        return !transaction.clientName;
    }

    static showLocationWarning(transaction: TransactionModel): boolean {
        return !transaction.location?.name;
    }

    static showUserWarning(transaction: TransactionModel): boolean {
        return !!transaction.user && !transaction.user.name;
    }

    static showCpidWarning(transaction: TransactionModel): boolean {
        return !transaction.cpId;
    }

    static getLocationNameOrId(transaction: TransactionModel): string {
        return transaction.location?.name || transaction.location?.id || null;
    }

    static getUserNameOrId(transaction: TransactionModel): string {
        return transaction.user?.name || transaction.user?.id || null;
    }

    static getStatusTooltip(transaction: TransactionTableModel): string {
        return transaction.status === TransactionStatusType.Blocked
            ? tooltipsConst.transactionBlockedDueCreditLimit
            : null;
    }

    static getUserNameToolTipForTable(transaction: TransactionTableModel) {
        return transaction?.showLocationWarning ? tooltipsConst.userNameNoLocation : tooltipsConst.userName;
    }

    static getUserNameToolTipForInfo(transaction: TransactionModel) {
        return !transaction?.location?.name ? tooltipsConst.userNameNoLocation : tooltipsConst.userName;
    }

    static getWarningTooltip(field: string, transaction?: TransactionTableModel): string {
        return field === 'userName' ? this.getUserNameToolTipForTable(transaction) : tooltipsConst[field];
    }

    static isExportDisabled(transaction: TransactionTableModel): boolean {
        return transaction?.status === TransactionStatusType.Error ||
            transaction?.status === TransactionStatusType.Blocked;
    }

    static viewActions(transaction: TransactionModel, type: ReconciliationType): boolean {
        return type === ReconciliationType.SelfCredit
            ? transaction.status === TransactionStatusType.Positive
                || transaction.status === TransactionStatusType.Counted
                ? false
                : transaction.status === TransactionStatusType.Reconciled
                    ? !!(transaction.postedAmount && transaction.mpAmount && transaction.postedAmount < 0)
                    : transaction.status === TransactionStatusType.Negative
                        ? transaction?.assignedTransactions?.length > 0
                        : true
            : transaction.status === TransactionStatusType.Canceled
                || transaction.status === TransactionStatusType.New
                || transaction.status === TransactionStatusType.Error;
    }

    static isPositive(element): boolean {
        return element.status === TransactionStatusType.Positive
            || (element.status === TransactionStatusType.Reconciled
                && element.source === TransactionSourceType.FromDifference
                && element.postedAmount > 0);
    }

    static isNegative(element): boolean {
        return element.status === TransactionStatusType.Negative
            || (element.status === TransactionStatusType.Reconciled
                && element.source === TransactionSourceType.FromDifference
                && element.postedAmount < 0);
    }

    static isClickable(element): boolean {
        return element.status !== TransactionStatusType.Positive;
    }

    static getPostedAmount(transaction: TransactionModel): string {
        return transaction.postedAmount ?
            transaction.status === TransactionStatusType.Positive ?
            `+${MoneyHelper.formatNumberWithThousandSeparator(transaction.postedAmount)}`
                : transaction.status === TransactionStatusType.Negative ?
                `${MoneyHelper.formatNumberWithThousandSeparator(MoneyHelper.round(transaction.mpAmount - transaction.postedAmount))} (${MoneyHelper.formatNumberWithThousandSeparator(transaction.postedAmount)})`
            : transaction.status === TransactionStatusType.Reconciled && transaction.source === TransactionSourceType.FromDifference ?
                (MoneyHelper.formatNumberWithThousandSeparator(MoneyHelper.round(transaction.mpAmount - transaction.postedAmount))) +
                ` ${transaction.postedAmount > 0 ?
                    ' (+' + MoneyHelper.formatNumberWithThousandSeparator(transaction.postedAmount) + ')'
                    : transaction.postedAmount < 0 ?
                        ' (' + MoneyHelper.formatNumberWithThousandSeparator(transaction.postedAmount) + ')'
                        : ''}`
                : MoneyHelper.formatNumberWithThousandSeparator(transaction.postedAmount)
            : MoneyHelper.formatNumberWithThousandSeparator(transaction.postedAmount);
    }

    static getMPAmount(transaction: TransactionModel): string {
        return MoneyHelper.formatNumberWithThousandSeparator(transaction.mpAmount);
    }

    static getPopoverContent(data: AssignedTransactionTableModel[]): string {
        if(!data) {
            return '';
        }
        let content = '<div class="reconciled-popover-content">';
        data.forEach(item => {
            content += (data.indexOf(item) === data.length - 1 ? '<div>'
                : '<div class="popover-item">') +
            `<span>
                <span class="popover-item-key">Bag Label Code: </span>
                <span class="popover-item-value">${item.bagLabelCode}, </span>
            </span>
            <span>
                <span class="popover-item-key">Posted Amount: </span>
                <span class="popover-item-value">${item.source === TransactionSourceType.FromDifference ? '+' : ''}${item.postedAmount}, </span>
            </span>
            <span>
                <span class="popover-item-key">Negative Amount Used: </span>
                <span class="popover-item-value">${item.negativeAmountUsed}, </span>
            </span>
            <span>
                <span class="popover-item-key">Deposit Date: </span>
                <span class="popover-item-value">${item.depositDate}, </span>
            </span>
            <span>
                <span class="popover-item-key">Status: </span>
                <span class="popover-item-value">${item.status}</span>
            </span></div>`;
            });
        content += '</div>';
        return content;
    }
}
